import $ from 'jquery'
import 'slick-carousel'
import lightbox from 'lightbox2'
window.jQuery = window.$ = $;
export const initSliders = () => {

    // HOMEPAGE TYPE - HERO SLIDER
    $('.hero-slider').slick({
        dots: true,
        infinite: true,
        speed: 600,
        delay: 6000,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        adaptiveHeight: true,
        arrows: false,
        fade: true,
        lazyLoad: 'ondemand',
        autoplay: true,
    });

    $('.image-slider').slick({
        dots: true,
        infinite: true,
        delay: 3000,
        autoplaySpeed: 3000,
        slidesToShow: 2,
        autoplay: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    });

    $('.article-slider').slick({
        dots: true,
        infinite: true,
        delay: 3000,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        autoplay: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    });

    $('.featured-slider').slick({
        dots: true,
        infinite: true,
        delay: 6000,
        slidesToShow: 1,
        autoplay: false,
        centerMode: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    centerMode: false,
                    arrows:false,
                }
            }
        ]
    });

    $('.testimonials-slider').slick({
        dots: false,
        infinite: true,
        delay: 10000,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        autoplay: true,
        centerMode: false,
        arrows: true,
    });

    $('.timeline').slick({
        dots: false,
        infinite: false,
        delay: 3000,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                }
            }
        ]
    }).addClass('show');

}
export default initSliders